<template>
  <div class="container">
    <alert
      v-if="feature"
      type="info"
      message="New feature! Shows all the categories available that will be assigned in the ingredients."
      @close="feature = false"></alert>
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} categories</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">
        Found {{meta.total}} categories that match '{{query}}'
      </span>
      <router-link
        v-if="can(uiPermissions.CATEGORIES_CREATE)"
        to="/categories/new"
        data-test="btn-new-category"
        class="btn btn-primary ml-auto">
        New category
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-categories" class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Name</th>
                  <th>Sub-categories</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                  </td>
                  <td>
                    <div>
                      <router-link v-if="can(uiPermissions.CATEGORIES_VIEW)" :to="`/categories/${item.id}`">{{item.name}}</router-link>
                      <span v-else>{{item.name}}</span>
                    </div>
                  </td>
                  <td>
                    <custom-tree :data="item.sub_categories" :options="treeOptions"/>
                  </td>
                  <td>
                    {{moment.utc(item.created_at).local().fromNow()}}
                  </td>
                  <td>
                    {{moment.utc(item.updated_at).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.CATEGORIES_VIEW])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        data-test="link-dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.CATEGORIES_VIEW)"
                          :to="`/categories/${item.id}`"
                          data-test="link-edit"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="5">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CustomTree from '@/components/CustomTree.vue';
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {categories} from '@/services';

export default {
  components: {
    [CustomTree.name]: CustomTree,
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      feature: true,
      treeOptions: {
        propertyNames: {
          children: 'sub_categories',
          text: 'name',
        },
      },
    };
  },
  methods: {
    fetchData(page) {
      return categories.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        with: 'categories',
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting category cannot be undone. Are you sure that you want to delete this category?')) {
        await categories.deleteById(item.id);
        this.refresh();
      }
    },
  },
};
</script>
